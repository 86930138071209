import React, { useEffect, useRef, useState } from "react";
import Check from "../../assets/check.svg";
import Document from "../../assets/document.png";
import Mail from "../../assets/order-mail.svg";
import Text from "components/Text";
import { Link } from "gatsby";
import OrderSummary from "LegalTranscription/Quote/OrderSummary";
import { servicesData } from "LegalTranscription/Quote/sample-data";

import { endpoints } from "core/utils/endpoints";
import http from "core/utils/http";
import textResolver from "core/utils/text-resolver";
import ata from "assets/ata.svg";
import iso9001 from "assets/iso-9001.svg";
import Loader from "components/Loader";
import Layout from "components/Layout";

function Success() {
  const [orderDetails, setOrderdetails] = useState({});
  const [loading, setLoading] = useState(false);
  const myRef = useRef(null);
  useEffect(() => {
    const quoteId = JSON.parse(window.localStorage.getItem("quoteId"));
    const apiUrl = endpoints.formal.orderSummary + "/" + quoteId;
    setLoading(true);
    http()
      .get(apiUrl)
      .then((res) => {
        setOrderdetails(res);
        setLoading(false);
      });
  }, []);
  const executeScroll = () => myRef.current.scrollIntoView();
  const { dataType = 0 } = servicesData;

  return (
    <Layout>
      <div className="py-24">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="max-w-2xl mx-auto text-black flex flex-col justify-center items-center space-y-8">
              <div className="flex justify-center items-center gap-4">
                <img src={Check} />
                <Text as="h3">Thank you for your purchase</Text>
              </div>
              <div className="w-full border-2 border-[#F5F5F5] p-4 rounded-2xl">
                <div className="flex items-center justify-between">
                  <img src={Document} />
                  <p className="text-[20px] leading-[17px] font-primary font-bold text-[#4A4A4A]">
                    Est. {orderDetails?.formal_quote?.est_from_delivery_time}-
                    {orderDetails?.formal_quote?.est_to_delivery_time}
                  </p>
                </div>
                <div className="flex items-center justify-between" ref={myRef}>
                  <p className="font-primary text-[14px] leading-[16px] text-[#757575] invisible">
                    Track your delivery status in{" "}
                    <strong className="text-black">Account</strong> &gt;{" "}
                    <strong className="text-black">Orders</strong>
                  </p>
                  <span
                    className="border border-[#5927B0] px-4 py-1 rounded-full text-[#5927B0] cursor-pointer"
                    onClick={executeScroll}
                  >
                    View Order
                  </span>
                </div>
              </div>
              <div className="w-full  border-2 border-[#F5F5F5] py-4 px-12 rounded-2xl">
                <div className="flex items-center justify-between">
                  <img src={Mail} />
                  <p className="text-[15px] leading-[18px] font-light font-primary break-words w-4/5">
                    We’ve sent a confirmation email to{" "}
                    <strong className="text-black font-bold">
                      {orderDetails?.user_details?.email}
                    </strong>
                    <br /> with the order details.
                  </p>
                </div>
              </div>

              <div className="bg-[#f4edff80] p-12 w-full max-w-[500px] rounded-2xl shadow-xl">
                <Text as="h3">Order Summary</Text>
                <div className="flex items-center justify-between">
                  <div>
                    <Text as="h5">
                      {orderDetails?.formal_quote?.quote_type ===
                      "certified_translation"
                        ? "Certified Translation"
                        : "Standard Translation"}
                    </Text>
                    <p>
                      {+orderDetails?.formal_quote?.total_page_count > 1
                        ? `${orderDetails?.formal_quote?.total_page_count} pages`
                        : `${orderDetails?.formal_quote?.total_page_count} page`}{" "}
                      ({+orderDetails?.formal_quote?.total_page_count * 250}{" "}
                      words max)
                    </p>
                  </div>
                  <Text as="h5">$29</Text>
                </div>
                {orderDetails?.formal_quote_items?.map((item) => (
                  <div
                    className="flex flex-start justify-between text-base xs:font-opensans font-bold my-4"
                    key={item.id}
                  >
                    <Text as="h5">{item.label}</Text>
                    <div className="flex gap-2 items-center">
                      <button className="bg-irisPurple rounded text-white flex items-center justify-center h-5 w-5 p-[3px] disabled:cursor-not-allowed">
                        <span className="pb-[2px]">-</span>
                      </button>
                      <span className="min-w-[20px] text-center text-balck">
                        {item.no_of_page}
                      </span>
                      <button className="bg-irisPurple rounded text-white flex items-center justify-center h-5 w-5 p-[3px] disabled:cursor-not-allowed">
                        <span>+</span>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="border"></div>
                <div className="flex items-center justify-between py-2">
                  {/* <h2 className="text-[18px] leading-[24px] font-opensans font-bold">
              <b>Total</b>
            </h2> */}
                  <Text as="h3">Total</Text>
                  <Text as="h1">
                    ${orderDetails?.formal_quote?.total_price}
                  </Text>
                </div>
              </div>
              <button className="px-8 bg-irisPurple py-3 text-white rounded-full">
                Order Again
              </button>
            </div>
            <div className="max-w-6xl mx-auto grid grid-cols-3 gap-4 mt-8">
              {servicesData.map((item, i) => (
                <div key={i} className="flex items-start gap-4">
                  <img src={item.image} className="mt-1" />
                  <div>
                    <Text
                      as="h5"
                      className="text-base text-[#333941] xs:font-opensans font-bold"
                    >
                      {dataType === 1
                        ? item.title
                        : textResolver(item, "title")}
                    </Text>
                    <Text
                      as="p"
                      className="text-[#5E6B7B] text-[15px] leading-[26px]"
                    >
                      {dataType === 1
                        ? item.description
                        : textResolver(item, "description")}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-8 flex items-center justify-center gap-8">
              <img src={ata} alt="" />
              <img src={iso9001} alt="" />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Success;
